import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import ThemeContext from "../context/ThemeContext"
import { css, Global } from "@emotion/core"
import PropTypes from "prop-types"
//import { Helmet } from "react-helmet"
import "antd/dist/antd.css"
import globalStyle from "../styles/global"
//import PWA from "./PWA"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  const [stickyClass] = useState("")

  /*useEffect(() => {
    window.addEventListener("scroll", stickyNavbar)
    return () => window.removeEventListener("scroll", stickyNavbar)
  }, [])

  const stickyNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY
      windowHeight > 150
        ? setStickyClass("sticky-nav")
        : setStickyClass("sticky-nav-hidden")
    }
  }*/

  return (
    <ThemeContext.Consumer>
      {() => (
        <Fragment>
          <Global
            styles={css`
              ${globalStyle}
            `}
          />
          <CookieConsent
            flipButtons
            location="none"
            buttonText="ยอมรับ"
            declineButtonText="ปฏิเสธ"
            cookieName="gatsby-gdpr-google-tagmanager"
            containerClasses={`CookieConsent ${stickyClass}`}
          >
            เพื่อการใช้งานเว็บไซต์ได้อย่างมีประสิทธิภาพ เราจึงมีการใช้คุกกี้
            อ่าน <Link to="/cookies-policy/">นโยบายคุกกี้</Link> เพิ่มเติม
          </CookieConsent>
          {children}
          {/* <PWA /> */}
        </Fragment>
      )}
    </ThemeContext.Consumer>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}
